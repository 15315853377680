<template>
  <div class="equipment-category-edit-container">
    <div class="operate">
      <dsn-button
          size="medium"
          icon="el-icon-back"
          type="primary"
          @click.native="handleBack"
      >{{ this.$t('commbtn.back') }}
      </dsn-button>
      <!--      <dsn-button-save-->
      <!--          @click.native="checkAdd('materialGroupForm')"-->
      <!--          v-if="hasUpdateBtnAuth"-->
      <!--      >保存-->
      <dsn-button-save
          @click.native="checkAdd(['equipmentFormRef'])" size="medium">{{ this.$t('commbtn.save') }}
      </dsn-button-save
      >
      <dsn-button
          size="medium"
          type
          plain
          icon="el-icon-refresh"
          class="reset-button"
          @click.native="handleReset(['equipmentFormRef'])"
      >{{ this.$t('commbtn.reset') }}
      </dsn-button>
    </div>
    <div class="info">
      <el-form
          :model="categoryForm"
          ref="equipmentFormRef"
          :inline="true"
          :rules="userFormRules"
          class="equipment-main-form"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item :label="this.$t('categories.fields.mainCategory')" prop="mainCategoryId"
                          :label-width="labelWidth">
              <el-select :disabled="categoryForm.middleCategoryId!=null&&categoryForm.subcategoryId!=null"
                         v-model="categoryForm.mainCategoryId"
                         clearable
                         filterable
                         @change="onMainCategoryChange"
                         :placeholder="this.$t('commmsg.pleaseSelect')+' '+this.$t('equipments.fields.mainCategory')"
                         class="category-input"
                         allow-create
                         value-key="key"
              >
                <el-option
                    v-for="(item,index) in mainCategoryParentList"
                    :key="index"
                    :label="item.label"
                    :value="item.id"
                />
              </el-select>
            </el-form-item>
            <!-- <el-form-item :label="this.$t('categories.fields.mainCategoryCode')" prop="mainCategoryCode"
                          :label-width="labelWidth">
              <dsn-input :disabled="categoryForm.middleCategoryId!=null&&categoryForm.subcategoryId!=null"
                         v-model.trim="categoryForm.mainCategoryCode"
                         :placeholder="this.$t('commmsg.pleaseInput')+' '+this.$t('categories.fields.mainCategoryCode')"
              ></dsn-input>
            </el-form-item> -->
            <el-form-item :label="this.$t('categories.fields.middleCategory')" prop="middleCategoryId"
                          :label-width="labelWidth">
              <el-select :disabled="isAddMainCategory&&categoryForm.middleCategoryId==null"
                         v-model="categoryForm.middleCategoryId"
                         clearable
                         filterable
                         :placeholder="this.$t('commmsg.pleaseSelect')+' '+this.$t('categories.fields.middleCategory')"
                         class="category-input"
                         @blur="mainCategoryBlur"
                         @change="onMiddleCategoryChange"
                         allow-create
                         value-key="key"
              >
                <el-option
                    v-for="(item,index) in middleCategoryList"
                    :key="index"
                    :label="item.label"
                    :value="item.id"
                />
              </el-select>
            </el-form-item>
            <!-- <el-form-item :label="this.$t('categories.fields.middleCategoryCode')" prop="middleCategoryCode"
                          :label-width="labelWidth">
              <dsn-input
                  v-model.trim="categoryForm.middleCategoryCode"
                  :placeholder="this.$t('commmsg.pleaseInput')+' '+this.$t('categories.fields.middleCategoryCode')"
                  :disabled="isAddMainCategory&&categoryForm.middleCategoryId==null"
              ></dsn-input>
            </el-form-item> -->
            <el-form-item :label="this.$t('categories.fields.subcategory')" prop="subcategoryName"
                          :label-width="labelWidth">
              <dsn-input
                  v-model.trim="categoryForm.subcategoryName"
                  :placeholder="this.$t('commmsg.pleaseInput')+' '+this.$t('categories.fields.subcategory')"
                  :disabled="categoryForm.subcategoryId==null"
              ></dsn-input>
            </el-form-item>
            <!-- <el-form-item :label="this.$t('categories.fields.subcategoryCode')" prop="subcategoryCode"
                          :label-width="labelWidth">
              <dsn-input
                  v-model.trim="categoryForm.subcategoryCode"
                  :placeholder="this.$t('commmsg.pleaseInput')+' '+this.$t('categories.fields.subcategoryCode')"
                  :disabled="categoryForm.subcategoryId==null"
              ></dsn-input>
            </el-form-item> -->
            <!--            <el-form-item :label="this.$t('equipmentCategory.fields.directorDept')" prop="directorDeptId"-->
            <!--                          :label-width="labelWidth">-->
            <!--              <dsn-input-->
            <!--                  v-model.trim="categoryForm.directorDeptId"-->
            <!--                  placeholder="负责部门"-->
            <!--              ></dsn-input>-->
            <!--            </el-form-item>-->
          </el-col>
        </el-row>
      </el-form>
      <!--      <el-tabs type="border-card">-->
      <!--        <el-tab-pane class="attribute-settings-info">-->
      <!--          <span slot="label">属性设置</span>-->
      <!--          <dsn-table-->
      <!--              border-->
      <!--              @row-click="selectCheckBox"-->
      <!--              ref="multipleTable"-->
      <!--              :data="[{-->
      <!--                'attributeName':'主分类',-->
      <!--                'value':'false'-->
      <!--              }]"-->
      <!--              tooltip-effect="dark"-->
      <!--              style="width: 250px"-->
      <!--          >-->
      <!--            <el-table-column prop="attributeName" label="属性" align="center"/>-->
      <!--            <el-table-column-->
      <!--                label="必填"-->
      <!--                align="center"-->
      <!--                width="100">-->
      <!--              <template slot-scope="scope">-->
      <!--                <el-checkbox v-model="scope.row.value"></el-checkbox>-->
      <!--              </template>-->
      <!--            </el-table-column>-->
      <!--          </dsn-table>-->
      <!--        </el-tab-pane>-->
      <!--      </el-tabs>-->
    </div>
  </div>

</template>
<script>

import {getEquipmentCategoryList, updateEquipmentCategory} from "@/api/efms/equipments/equipment.category.api";
import _ from "lodash";
import {mapGetters} from "vuex";
import arrayToTree from "array-to-tree";

export default {
  name: 'equipmentAdd',
  data() {
    return {
      isAddMiddleCategory: true,
      isAddMainCategory: true,//初始化添加主类型的
      isEditStation: false, //是否可编辑站位
      categoryForm: {
        parentCategoryId: null,
        mainCategoryId: null,
        mainCategoryName: null,
        mainCategoryCode: null,
        middleCategoryName: null,
        middleCategoryId: null,
        middleCategoryCode: null,
        subcategoryCode: null,
        subcategoryId: null,
        categoryCode: null,
        categoryName: null,
        categoryId: null,
        subcategoryName: null,
        isSub: false,
        directorDeptId: null,
        executorId: null,
      },
      attributeForm: {},
      scheduleDetailList: [],
      selectionList: [],
      labelWidth: "120px",
      // equipmentCategoryList: [],
      equipmentSubcategoryList: [],
      conditionList: [],
      mainDeptList: [],
      brandList: [],
      executorList: [],
      categoryParentList: [{
        label: '电脑',
        id: '2',
        key: 'computer',
        value: 'computer',
        categoryCode: 'A001'
      },
        {
          label: '消防器材',
          id: '1',
          key: 'fire',
          value: 'fire',
          categoryCode: 'B001'
        }],
      equipmentImportDialog: {
        helpText: (data) => {
          return data
        },
        equipmentList: [],
        selectedEquipmentList: [],
        loading: false,
        equipmentDialog: false,
        equipmentForm: {
          equipmentCategoryId: null,
        }
      },
      mainCategoryParentList: [],
      middleCategoryList: [],
      fullMiddleCategoryList: [],
    }
  },
  created() {
    this.operateType = this.$route.query.operateType;
    this.cloneList = _.cloneDeep(JSON.parse(JSON.stringify(this.equipmentCategoryList)));
    this.categoryForm =
        this.cloneList[0] ||
        JSON.parse(localStorage.getItem("equipmentCategoryList"))[0];
    console.info(this.categoryForm)
    
    if (this.categoryForm.mainCategoryId != null && this.categoryForm.middleCategoryId == null && this.categoryForm.subcategoyId == null) {
      this.isAddMainCategory = true;
      this.isAddMiddleCategory = false;
    } else if (this.categoryForm.mainCategoryId != null && this.categoryForm.middleCategoryId != null && this.categoryForm.subcategoyId == null) {
      this.isAddMainCategory = true;
      this.isAddMiddleCategory = true;
    } else if (this.categoryForm.mainCategoryId != null && this.categoryForm.middleCategoryId != null && this.categoryForm.subcategoyId != null) {
      this.isAddMainCategory = false;
      this.isAddMiddleCategory = false;
      // setTimeout(()=>{
      //   this.loadMiddleCategoryByParent(this.categoryForm.mainCategoryId)
      // },1000)
    } else {
      console.error("unknow edit type")
    }
	this.initSelectData();
  },
  computed: {
    ...mapGetters(["equipmentCategoryList"]),
    userFormRules: function () {
      let that = this
      return {
        scheduleNo: [{required: true, message: "请输入盘点单号", trigger: "change"}],
        equipmentCategoryId: [{
          required: false,
          message: `${that.$t('commmsg.pleaseInput')}${that.$t('inventorySchedule.fields.category')}`,
          trigger: "change"
        }],
        equipmentSubcategoryId: [{
          required: false,
          message: `${that.$t('commmsg.pleaseInput')}${that.$t('inventorySchedule.fields.subcategory')}`,
          trigger: "change"
        }],
        executorId: [{
          required: true,
          message: `${that.$t('commmsg.pleaseInput')}${that.$t('inventorySchedule.fields.executor')}`,
          trigger: "change"
        }],
      }
    }
  },
  methods: {
    handleBack() {
      this.$router.pushAndClear(
          {name: "equipmentCategory"},
          {
            deleteItem: this.$route,
            current: this.$route,
          }
      );
    },
    checkAdd(formName) {
      let count = 0;
      formName.forEach((element) => {
        this.$refs[element].validate((valid) => {
          if (valid) {
            count++;
          } else {
            return false;
          }
        });
      });
      if (count >= 1) {
        this.messageBox({
          type: "success",
          title: "保存",
          content: "是否保存数据?",
        })
            .then(() => {
              this.handleDataSave();
            })
            .catch(() => {
              this.notification().warn({
                body: "已取消保存",
              });
            });
      }
    },
    handleDataSave() {
      let postData = {};
      //添加主分类
      if (this.categoryForm.mainCategoryId && this.categoryForm.middleCategoryId == null && this.categoryForm.subcategoryId == null) {//update main
        Object.assign(postData, {
          id: this.categoryForm.mainCategoryId,
          categoryName: this.categoryForm.mainCategoryName,
          categoryNameEn: this.categoryForm.mainCategoryName,
          categoryNameOther: this.categoryForm.mainCategoryName,
          categoryCode: this.categoryForm.mainCategoryCode,
          parentId: null,
          directorDeptId: this.categoryForm.directorDeptId,
          attributeSettings: null,
        })
      } else if (this.categoryForm.mainCategoryId && this.categoryForm.middleCategoryId != null && this.categoryForm.subcategoryId == null) {
        Object.assign(postData, {
          id: this.categoryForm.middleCategoryId,
          categoryName: this.categoryForm.middleCategoryName,
          categoryNameEn: this.categoryForm.middleCategoryName,
          categoryNameOther: this.categoryForm.middleCategoryName,
          categoryCode: this.categoryForm.middleCategoryCode,
          parentId: this.categoryForm.mainCategoryId,
          directorDeptId: this.categoryForm.directorDeptId,
          attributeSettings: null,
        })
      } else if (this.categoryForm.mainCategoryId && this.categoryForm.middleCategoryId != null && this.categoryForm.subcategoryId != null) {
        console.info("小分类")
        Object.assign(postData, {
          id: this.categoryForm.subcategoryId,
          categoryName: this.categoryForm.subcategoryName,
          categoryNameEn: this.categoryForm.subcategoryName,
          categoryNameOther: this.categoryForm.subcategoryName,
          categoryCode: this.categoryForm.subcategoryCode,
          parentId: this.categoryForm.middleCategoryId,
          directorDeptId: this.categoryForm.directorDeptId,
          attributeSettings: null,
        })
      } else {
        console.info("error type")
      }
      console.info(postData)
      updateEquipmentCategory(postData).then(result => {
        const {code, status, data, errMsg} = result;
        if (status === 200 && data.resultCode === 0) {
          this.notification().success({
            body: "保存成功",
          });
          this.handleBack();
        } else {
          this.notification().error({
            body: data.errMsg,
          });
        }
      })
    },
    handleReset(formName) {
      if (this.operateType === "add") {
        formName.forEach((element) => {
          this.$refs[element].resetFields();
        });
      }
    },
    initSelectData() {
      this.initCategoryList();
      this.initUserList();
    },
    initCategoryList() {
      
      this.getMainCategoryList();
      this.getMiddleCategoryList();
	  // this.getCategoryMainList();
    },
    initSubcategory(categoryId) {

    },
    getMainCategoryList() {//获取主分类下拉框
      let queryParams = {
        page: 0,
        pageSize: 0
      }
      getEquipmentCategoryList(queryParams).then(res => {
        const {status, data} = res
        if (status === 200) {
          let tempList = data.data;
          if (tempList != null && tempList.length > 0) {
            let result = tempList.filter(s => s["parentId"] == null || s['parentId'] === "")
                .map((res) => {
                  return {
                    id: res.id,
                    label: res.categoryName,
                    value: res.id,
                    key: res.id,
                    code: res.categoryCode,
                    data: res
                  }
                })
            this.mainCategoryParentList = [...result];
          }
        }
      })
    },
    initBrandList() {
      this.brandList.push({
            id: '1',
            label: '华硕',
            value: '华硕',
            key: 'asus'
          },
          {
            id: '2',
            label: '小米',
            value: 'xiaomi',
            key: 'xiaomi'
          })
    },
    initConditionList() {
      this.conditionList.push(
          {
            id: '1',
            label: '正常',
            value: 'ok',
            key: 'ok'
          }, {
            id: '2',
            label: '停用',
            value: 'stop',
            key: 'stop'
          }
      )
    },
    initUserList() {
      this.executorList.push(
          {
            id: '1',
            label: 'user1',
            value: '1',
            key: '1'
          }, {
            id: '2',
            label: 'user2',
            value: '2',
            key: '2'
          }
      )
    },
    handleDetailTableSelectionChange() {

    },
    selectCheckBox() {

    },
    handleDetailAdd() {
      this.equipmentImportDialog.equipmentDialog = true
    },
    handleDetailEdit() {

    },
    handleDetailDeleteCheck() {

    },
    handleSelectionWorkCenter() {

    },
    handleCancleWorCenter() {
      this.equipmentImportDialog.equipmentDialog = false

    },
    queryWorkCenter() {

    },
    handleResetWorkCenterCondition() {

    },
    mainCategoryBlur(e) {

    },
    onMiddleCategoryChange(e) {
      console.info(e)
      let dataType = Object.prototype.toString.call(e);
      if (e != null) {
        if ('[object String]' === dataType) {
          let targetItems = this.middleCategoryList.filter(s => s["id"] === e)
          if (targetItems != null && targetItems.length >= 1) {
            let tempTarget = targetItems[0];
            this.categoryForm.middleCategoryCode = tempTarget["code"]
            this.categoryForm.middleCategoryName = tempTarget["label"];
          }
        }
      } else {
        this.categoryForm.middleCategoryCode = null
        this.categoryForm.middleCategoryName = null;
      }
    },
    getCategoryMainList() {//获取主分类下拉框
      let queryParams = {
        page: 1,
        pageSize: 100
      }
      getEquipmentCategoryList(queryParams).then(res => {
        const {status, data} = res
        if (status === 200) {
          console.info(status)
          let tempList = data.data;
          if (tempList != null && tempList.length > 0) {
            let result = tempList.filter(s => s["parent_id"] === "").map(a => {
              return {
                label: a["category_name"],
                key: a["id"],
                value: a["id"],
                code: a["category_code"]
              }
            })
            this.categoryParentList = [...result]
          }
        }
      })
    },
    onMainCategoryChange(e) {
      console.info(e)
      let dataType = Object.prototype.toString.call(e);
      console.info(dataType)
      if ('[object String]' === dataType) {//编辑模式下不能新增主类
        let target = this.mainCategoryParentList.filter(s => s["id"] === e);
        if (target != null && target.length >= 1) {
          let temp = target[0];
          console.info(temp)
          this.categoryForm.mainCategoryName = temp['label']
          this.categoryForm.mainCategoryCode = temp['code']
          this.loadMiddleCategoryByParent(e["id"])
        } else {
          this.cloneList = _.cloneDeep(JSON.parse(JSON.stringify(this.equipmentCategoryList)));
          let temp =
              this.cloneList[0] ||
              JSON.parse(localStorage.getItem("equipmentCategoryList"))[0];
          console.info(temp)
          this.categoryForm.mainCategoryId = temp['mainCategoryId']//recover
          this.$message.error("编辑模式下无法添加新的主类")
        }
        // this.categoryForm.directorDeptId
      }
    },
    getMiddleCategoryList() {//获取主分类下拉框
      let queryParams = {
        page: 0,
        pageSize: 0
      }
      getEquipmentCategoryList(queryParams).then(res => {
        const {status, data} = res
        if (status === 200) {
          let tempList = data.data;
          if (tempList != null && tempList.length > 0) {
            let treeData = arrayToTree(tempList, {
              parentProperty: 'parentId',
              childrenProperty: 'children'
            })
            console.info(treeData)
            let categoryList = []
            treeData.forEach((item) => {
              console.info(item)
              if (item != null && item.children != null) {
                for (let i = 0; i < item.children.length; i++) {
                  console.info(i)
                  let res = item.children[i];
                  if (res != null) {
                    let tItem = {
                      id: res.id,
                      label: res.categoryName,
                      value: res.id,
                      key: res.id,
                      code: res.categoryCode,
                      data: res,
                      parentId: item.id,
                    }
                    categoryList.push(tItem);
                  }
                  console.info(this.fullMiddleCategoryList)
                }
              }
            })
            this.fullMiddleCategoryList = [...categoryList]
            this.loadMiddleCategoryByParent(this.categoryForm.mainCategoryId)
            console.info(this.fullMiddleCategoryList)
          }
        }
      })
    },
    loadMiddleCategoryByParent(id) {
      console.info('load middle')
      console.info(this.fullMiddleCategoryList)
      if (this.fullMiddleCategoryList != null && this.fullMiddleCategoryList.length > 0) {
        console.info(this.fullMiddleCategoryList);
        this.middleCategoryList = [...this.fullMiddleCategoryList.filter(s => s["parentId"] != null && s["parentId"] === id)];
        this.$forceUpdate();
      }
    }

  }
}
</script>
<style lang="scss">

.equipment-category-edit-container {
  .operate {
    padding: 16px;

    .el-button {
      margin: 0 8px 0 0;
    }
  }

  .info {
    background-color: #ffffff;
    padding: 16px;

    .equipmentFormTop {
      .el-form-item {
        margin: 0 24px 16px 0;
      }
    }

    .equipment-main-form {
      .el-form-item {
        margin: 0 24px 16px 0;

        .el-select {
          width: 320px;
        }

        .el-input {
          width: 320px;
        }
      }
    }
  }

  .equipment-query-form {
    .el-form-item__label {
      padding: 0;
    }
  }
}
</style>
