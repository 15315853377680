<template>
  <div class="equipment-category-container">
    <DsnPanel>
      <div slot="header" class="title clearfix">
        <el-form
            :inline="true"
            :model="queryParam"
            ref="categoryFormRef"
            :rules="{}"
            @submit.native.prevent
        >
          <el-form-item :label="`${this.$t('categories.fields.categoryName')}:`" prop="keywords">
            <dsn-input v-model="queryParam.keywords" size="medium"
                       :placeholder="`${this.$t('commmsg.pleaseInput')+' '+this.$t('categories.fields.categoryName')}`"></dsn-input>
          </el-form-item>
          <el-form-item>
            <dsn-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleQuery(['categoryFormRef'])"
            >{{ this.$t('commbtn.query') }}
            </dsn-button>
            <dsn-button
                size="medium"
                type
                plain
                icon="el-icon-refresh"
                class="reset-button"
                @click="handleReset('categoryFormRef')"
            >{{ this.$t('commbtn.reset') }}
            </dsn-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="operation">
        <dsn-button
            size="medium"
            type="success"
            icon="el-icon-folder-add"
            @click.native="handleAdd"
			v-if="isAddBtn == true"
        >{{ this.$t('commbtn.add') }}
        </dsn-button>
        <dsn-button
            size="medium"
            type="primary"
            icon="el-icon-edit"
            :disabled="selectionList.length !== 1"
            @click="handleEdit"
			v-if="isEditBtn == true"
            style="margin-left:0"
        >{{ this.$t('commbtn.edit') }}
        </dsn-button>
        <dsn-button
            size="medium"
            type="danger"
            icon="el-icon-delete"
            style="margin-left:0"
            :disabled="selectionList.length !==1"
            @click="handleDeleteCheck"
			v-if="isDelBtn == true"
        >{{ this.$t('commbtn.delete') }}
        </dsn-button>
      </div>

		
		<!-- <dsn-table
		          @row-click="selectCheckBox"
		          @selection-change="handleSelectionChange"
		          ref="multipleTable"
		          :row-key="'id'"
		          v-loading="this.pagingConfig.tableLoading"
		          :data="pagingConfig.tableData"
		          tooltip-effect="dark"
		          style="width: 100%;" :tree-props="{children: 'list'}"
		      >
		        <el-table-column type="selection" width="55" align="center"/>
				<el-table-column prop="categoryName" :label="this.$t('categories.fields.mainCategory')" align="center"/>
				<el-table-column prop="categoryCode" :label="this.$t('categories.fields.categoryCode')" align="center"/>
		        
		      </dsn-table> @row-click="selectCheckBox"-->
			  
		<!-- <el-table max-height='750' :empty-text="$t('commbtn.tableEmpty')" :data="pagingConfig.tableData"style="width: 100%" stripe row-key="id" :tree-props="{children: 'list'}" tooltip-effect="dark" default-expand-all v-loading="pagingConfig.tableLoading" ref="multipleTable" @selection-change="handleSelectionChange">
			
			
			<el-table-column type="selection" width="55" align="center" label="序号" fixed="left" :selectable="selectable"/>
			
			<el-table-column prop="categoryName" :label="this.$t('categories.fields.categoryName')" align="left"/>
			<el-table-column prop="categoryCode" :label="this.$t('categories.fields.categoryCode')" align="center"/>
			
			
		</el-table> -->
		
      <dsn-table
        v-loading="pagingConfig.tableLoading"
          :border="true"
          @row-click="selectCheckBox"
          ref="multipleTable"
          :data="pagingConfig.tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center" label="序号" fixed="left"/>
        <el-table-column prop="mainCategoryName" :label="this.$t('categories.fields.mainCategory')" align="center"
        />
        <el-table-column prop="middleCategoryName" :label="this.$t('categories.fields.middleCategory')" align="center"
        />
        <el-table-column prop="subcategoryName" :label="this.$t('categories.fields.subcategory')" align="center"/>
        <el-table-column prop="categoryCode" :label="this.$t('categories.fields.categoryCode')" align="right"/>
      </dsn-table>
    </DsnPanel>
    <el-dialog title="删除" :visible.sync="deleteDialog" :width="defaltDialogWidth" v-el-drag-dialog>
      <span>是否确认删除{{ selectionList.length }}条数据？</span>
      <span slot="footer" class="dialog-footer">
        <dsn-button @click="deleteDialog = false">取 消</dsn-button>
        <dsn-button type="primary" @click="handleDelete">确 定</dsn-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {mapMutations} from "vuex";
import DsnFooter from "@/views/layout/dsn-footer";
import {deleteOneCategory, getEquipmentCategoryList} from "@/api/efms/equipments/equipment.category.api";
import arrayToTree from "array-to-tree";

export default {
  name: 'equipmentCategoryLists',
  components: {DsnFooter},
  inject: ["defaltDialogWidth"],
  data() {
    return {
      listData: [],
      queryParam: {
        keywords: null,
      },
      showPage: true,
      pagingConfig: {
        current: 0,
        pageSize: 0,
        count: 0,
        tableLoading: false,
        total: 0,
        tableData: []
      },
      deleteDialog: false,
      selectionList: [],
	  
	  isAddBtn:false,
	  isEditBtn:false,
	  isDelBtn:false,
    }
  },
  computed: {},
  created() {
    this.getCategoriesList();
	
	var permissionsList = JSON.parse(localStorage.getItem("USER_PERMISSIONS"));
	
	
	if(permissionsList.indexOf("api:categories:add") != -1){
		this.isAddBtn = true;
	}
	if(permissionsList.indexOf("api:categories:edit") != -1){
		this.isEditBtn = true;
	}
	if(permissionsList.indexOf("api:categories:del") != -1){
		this.isDelBtn = true;
	}

  },
  activated() {
    this.getCategoriesList();
  },
  methods: {
	  
	   selectable(row, index) {
	        // console.log('row=', row, index)
	        if (row.showbtn == true) {
	          return true
	        } else {
	          return false
	        }
	      },
	  
    ...mapMutations(["EQUIPMENTCATEGORYLIST"]),
    handleQuery(formNames) {
      this.$refs[formNames].validate(valid => {
        if (valid) {
          this.getCategoriesList();
        } else {
          return false;
        }
      });
    },
    handleReset(formName) {
      this.$refs[formName].resetFields();
      this.handleQuery(formName);
    },
    handlePageSizeChange(pageSize) {
      this.pagingConfig.pageSize = pageSize;
      this.pagingConfig.current = 1;
      this.getCategoriesList()
    },
    handleCurrentChange(currentChange) {
      console.info(currentChange)
      this.pagingConfig.current = currentChange;
      this.getCategoriesList();
    },
    handleSelectionChange(val) {
      this.selectionList = val;
    },
    selectCheckBox(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    handleEdit() {
      const tempArr = JSON.parse(JSON.stringify(this.selectionList));
      this.EQUIPMENTCATEGORYLIST(tempArr);
      console.info(tempArr)
      localStorage.setItem("equipmentCategoryList", JSON.stringify(this.selectionList));
      this.$router.push({
        name: "equipmentCategoryEdit",
        query: {operateType: "edit"}
      });
    },
    handleAdd() {
      // const tempArr = JSON.parse(JSON.stringify(this.selectionList));
      this.EQUIPMENTCATEGORYLIST([]);
      // localStorage.setItem("positionRefList", JSON.stringify(this.selectionList));
      this.$router.push({
        name: "equipmentCategoryAdd",
        query: {operateType: "add"}
      });
    },
    handleDelete() {
      const {selectionList} = this;
      // console.info(selectionList)
      if (selectionList.length === 1) {
        deleteOneCategory(selectionList[0].id).then(res => {
          const {status, data} = res;
          if (status === 200 && data.resultCode === 0) {
            this.getCategoriesList();
            this.$message.success("ok");
          } else {
            this.$message.error(`删除失败 ${data.errMsg}`);
          }
        }).catch(err => {
          this.$message.error("删除失败");
        })
      } else {
        this.$message.error("请选择一条要删除的记录")
      }
    },
    handleDeleteCheck() {
      this.messageBox()
          .then(() => {
            this.handleDelete();
          })
          .catch(() => {
            this.notification().warn({
              body: "已经取消删除"
            });
          });
    },
    getCategoriesList() {
      // this.initMainList();
      this.initFullCategoryList(true);
    },
    initFakeData() {
      for (let i = 0; i <= 100; i++) {
        this.listData.push({
          id: i,
          equipmentCategory: '电脑' + i,
          equipmentCategoryId: '电脑' + i,
          equipmentSubcategory: '子类目' + i,
          equipmentSubcategoryId: '子类目' + i,
          genTotalCount: 1000,
          unUseCount: 50
        })
      }
    },
    initMainList(showPage) {
      if (!showPage) {
        this.pagingConfig.currentPage = 1;
      }
      const data = {
        ...this.queryParam,
        page: this.pagingConfig.current,
        pageSize: this.pagingConfig.pageSize
      };
      this.showPage = showPage;
      this.pagingConfig.tableLoading = true
      getEquipmentCategoryList(data).then((response) => {
        const {status, errMsg, data} = response;
        if (status === 200) {
          this.showPage = true;
          this.pagingConfig.total = data.totalCount;
          let tempList = arrayToTree(data.data, {
            parentProperty: 'parentId',
            customID: 'id'
          })
          let resultList = []
		  
		  
		  
          // tempList.forEach((v) => {//大分类
          //   Object.assign(v, {
          //     mainCategoryName: v.categoryName,
          //     mainCategoryCode: v.categoryCode,
          //     mainCategoryId: v.id,
          //   })
          //   resultList.push(v);
          //   if (v.children != null && v.children.length > 0) {
          //     v.children.forEach((x) => {
          //       x.middleCategoryName = x.categoryName;
          //       x.middleCategoryCode = x.categoryCode;
          //       x.middleCategoryId = x.id
          //       x.mainCategoryName = v.categoryName;
          //       x.mainCategoryCode = v.categoryCode;
          //       x.mainCategoryId = v.id;
          //       if (x.children != null && x.children.length > 0) {
          //         x.children.forEach(d => {
          //           d.middleCategoryName = x.categoryName;
          //           d.middleCategoryCode = x.categoryCode;
          //           d.middleCategoryId = x.id
          //           d.mainCategoryName = v.categoryName;
          //           d.mainCategoryCode = v.categoryCode;
          //           d.mainCategoryId = v.id;
          //           d.subcategoryName = d.categoryName;
          //           d.subcategoryCode = d.categoryCode;
          //           d.subcategoryId = d.id;
          //           resultList.push(d);
          //         })
          //       }
          //       resultList.push(x);
          //     })
          //   }
          // })
          this.pagingConfig.tableData = [...resultList]
		  
          this.pagingConfig.tableLoading = false
        } else {
          this.notification().warn({
            body: errMsg
          });
        }
      }).catch(err => {
        console.info(err)
      })
    },

    initFullCategoryList(showPage) {
      this.pagingConfig.tableLoading=true;
      const data = {
        page: 0,
        pageSize: 0
      };
      const searchData = {
        ...this.queryParam,
        page: this.pagingConfig.current,
        pageSize: this.pagingConfig.pageSize
      };
      this.$axios.all([getEquipmentCategoryList(data), getEquipmentCategoryList(searchData)])
          .then(res => {
            let fullResult = res[0]
            let searchResult = res[1]
            if (fullResult != null) {
              const {status, data} = fullResult;
              let fullList = [];
              let convertedResult = []
              if (status === 200 && data.data != null && data.data.length > 0) {
                fullList = [...data.data]
              }
              if (searchResult != null) {
                const {status, data} = searchResult;
                if (status === 200 && data.data.length > 0) {//search 结果需要重新设置
                  let tempSearchList = data.data;
                  tempSearchList.forEach(v => {
                    let parentId = v.parentId;//当前节点的父id
                    if (parentId != null && parentId !== '') {//有父id
                      let targetItems = fullList.filter(s => s["id"] === parentId)
                      if (targetItems != null && targetItems.length === 1) {//父节点存在 判断是一级父节点还是二级父节点
                        let parentNode = targetItems[0];
                        if (parentNode != null) {
                          let nodeParentId = parentNode["parentId"];
                          if (nodeParentId != null && nodeParentId !== "") {//还有父节点 那么当前节点是第三级
                            let nodeTargetItems = fullList.filter(s => s["id"] === nodeParentId)
                            if (nodeTargetItems != null && nodeTargetItems.length === 1) {
                              let nodeParentNode = nodeTargetItems[0];
                              if (nodeParentNode != null) {
                                Object.assign(v, {
                                  mainCategoryName: nodeParentNode["categoryName"],
                                  mainCategoryCode: nodeParentNode["categoryCode"],
                                  mainCategoryId: nodeParentNode["id"],
                                  middleCategoryName: parentNode["categoryName"],
                                  middleCategoryCode: parentNode["categoryCode"],
                                  middleCategoryId: parentNode["id"],
                                  subcategoryName: v["categoryName"],
                                  subcategoryCode: v["categoryCode"],
                                  subcategoryId: v["id"],
                                })
                                convertedResult.push(v);
                              }
                            }
                          } else {//第二级
                            Object.assign(v, {
                              mainCategoryName: parentNode["categoryName"],
                              mainCategoryCode: parentNode["categoryCode"],
                              mainCategoryId: parentNode["id"],
                              middleCategoryName: v["categoryName"],
                              middleCategoryCode: v["categoryCode"],
                              middleCategoryId: v["id"]
                            })
                            convertedResult.push(v);
                          }
                        }
                      }
                    } else {//没父节点就是大分类
                      Object.assign(v, {
                        mainCategoryName: v.categoryName,
                        mainCategoryCode: v.categoryCode,
                        mainCategoryId: v.id
                      })
                      convertedResult.push(v);//一级目录
                    }
                  })

                }
              }
              this.pagingConfig.tableData = [...convertedResult];
			  // this.pagingConfig.tableData.forEach(dataobj=>{
				 //  dataobj.delFlag = dataobj.delFlag == 0? '使用中':'停用';
				  
				 //  dataobj.showbtn = false;
				 //  dataobj.level = 'first';
				 //  dataobj.list.forEach(next1obj => {
				 //  	next1obj.showbtn = false;
				 //  	next1obj.level = 'second';
				 //  	next1obj.list.forEach(next2obj => {
				 //  		next2obj.showbtn = true;
				 //  		next2obj.level = 'third';
				 //  	})
				 //  })
				  
				  
			  // })
			  
			  
              this.pagingConfig.tableLoading=false;
            }
          })
          .catch(err => {

          })

    }
  }
}
</script>
<style lang="scss" scoped>

.equipment-category-container {
  .operation {
    width: 100%;
    height: 48px;

    .dsn-button {
      margin: 0 8px 0 0;
    }
  }
}

//::v-deep.el-table .cell {
//  font-size: 18px;
//}


</style>
