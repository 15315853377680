<template>
  <div class="equipment-category-add-container">
    <div class="operate">
      <dsn-button
          size="medium"
          icon="el-icon-back"
          type="primary"
          @click.native="handleBack"
      >{{ this.$t('commbtn.back') }}
      </dsn-button>
      <!--      <dsn-button-save-->
      <!--          @click.native="checkAdd('materialGroupForm')"-->
      <!--          v-if="hasUpdateBtnAuth"-->
      <!--      >保存-->
      <dsn-button-save size="medium"
                       @click.native="checkAdd(['equipmentFormRef'])">{{ this.$t('commbtn.save') }}
      </dsn-button-save
      >
      <dsn-button
          size="medium"
          type
          plain
          icon="el-icon-refresh"
          class="reset-button"
          @click.native="handleReset(['equipmentFormRef'])"
      >{{ this.$t('commbtn.reset') }}
      </dsn-button>
    </div>
    <div class="info">
      <el-form
          :model="categoryForm"
          ref="equipmentFormRef"
          :inline="true"
          :rules="userFormRules"
          class="equipment-main-form"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item :label="this.$t('equipments.fields.mainCategory')" prop="mainCategoryId"
                          :label-width="labelWidth">
              <el-select
                  v-model="categoryForm.mainCategoryId"
                  clearable
                  filterable
                  @change="onMainCategoryChange"
                  :placeholder="this.$t('commmsg.pleaseSelect')+' '+this.$t('equipments.fields.mainCategory')"
                  class="category-input"
                  allow-create
                  value-key="key"
              >
                <el-option
                    v-for="(item,index) in mainCategoryParentList"
                    :key="index"
                    :label="item.label"
                    :value="item"
                />
              </el-select>
            </el-form-item>
            <!-- <el-form-item :label="this.$t('categories.fields.mainCategoryCode')" prop="mainCategoryCode"
                          :label-width="labelWidth">
              <dsn-input
                  v-model.trim="categoryForm.mainCategoryCode"
                  :placeholder="this.$t('commmsg.pleaseInput')+' '+this.$t('categories.fields.mainCategoryCode')"
              ></dsn-input>
            </el-form-item> -->
            <el-form-item :label="this.$t('categories.fields.middleCategory')" prop="middleCategoryId"
                          :label-width="labelWidth">
              <el-select :disabled="isAddMainCategory"
                         v-model="categoryForm.middleCategoryId"
                         clearable
                         filterable
                         :placeholder="this.$t('commmsg.pleaseSelect')+' '+this.$t('categories.fields.middleCategory')"
                         class="category-input"
                         @blur="mainCategoryBlur"
                         @change="mainCategoryChange"
                         allow-create
                         value-key="key"
              >
                <el-option
                    v-for="(item,index) in middleCategoryList"
                    :key="index"
                    :label="item.label"
                    :value="item"
                />
              </el-select>
            </el-form-item>
            <!-- <el-form-item :label="this.$t('categories.fields.middleCategoryCode')" prop="middleCategoryCode"
                          :label-width="labelWidth">
              <dsn-input
                  v-model.trim="categoryForm.middleCategoryCode"
                  :placeholder="this.$t('commmsg.pleaseInput')+' '+this.$t('categories.fields.middleCategoryCode')"
                  :disabled="isAddMainCategory"
              ></dsn-input> -->
            </el-form-item>
            <el-form-item :label="this.$t('categories.fields.subcategory')" prop="subcategoryName"
                          :label-width="labelWidth">
              <dsn-input
                  v-model.trim="categoryForm.subcategoryName"
                  :placeholder="this.$t('commmsg.pleaseInput')+' '+this.$t('categories.fields.subcategory')"
                  :disabled="isAddMiddleCategory||isAddMainCategory" size="medium"
              />
            </el-form-item>
            <!-- <el-form-item :label="this.$t('categories.fields.subcategoryCode')" prop="subCategoryCode"
                          :label-width="labelWidth">
              <dsn-input size="medium"
                         v-model.trim="categoryForm.subCategoryCode"
                         :placeholder="this.$t('commmsg.pleaseInput')+' '+this.$t('categories.fields.subcategoryCode')"
                         :disabled="isAddMiddleCategory||isAddMainCategory"
              />
            </el-form-item> -->
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>

</template>
<script>

import {addEquipmentCategory, getEquipmentCategoryList} from "@/api/efms/equipments/equipment.category.api";
import {getDepartmentList} from "@/api/efms/department/department.api";
import arrayToTree from "array-to-tree";

export default {
  name: 'categoryAdd',
  data() {
    return {
      isAddMainCategory: true,//初始化添加大类
      isAddMiddleCategory: false,//初始化添加中分类
      isEditStation: false, //是否可编辑站位
      categoryForm: {
        mainCategoryName: null,
        mainCategoryId: null,
        mainCategoryCode: null,
        middleCategoryName: null,
        middleCategoryCode: null,
        middleCategoryId: null,
        parentCategoryId: null,
        subCategoryCode: null,
        subcategoryId: null,
        categoryCode: null,
        categoryName: null,
        categoryId: null,
        subcategoryName: null,
        isSub: false,
        directorDeptId: null,
        executorId: null,
      },
      attributeForm: {},
      scheduleDetailList: [],
      selectionList: [],
      labelWidth: "120px",
      equipmentCategoryList: [],
      equipmentSubcategoryList: [],
      conditionList: [],
      mainDeptList: [],
      brandList: [],
      executorList: [],
      categoryParentList: [],
      departmentOptionList: [],
      mainCategoryParentList: [],
      middleCategoryList: [],
      fullMiddleCategoryList: [],
      equipmentImportDialog: {
        helpText: (data) => {
          return data
        },
        equipmentList: [],
        selectedEquipmentList: [],
        loading: false,
        equipmentDialog: false,
        equipmentForm: {
          equipmentCategoryId: null,
        }
      }
    }
  },
  created() {
    this.operateType = this.$route.query.operateType;
    this.initSelectData();
  },
  computed: {
    userFormRules: function () {
      let that = this
      return {
        scheduleNo: [{required: true, message: "请输入盘点单号", trigger: "change"}],
        equipmentCategoryId: [{
          required: false,
          message: `${that.$t('commmsg.pleaseInput')}${that.$t('inventorySchedule.fields.category')}`,
          trigger: "change"
        }],
        equipmentSubcategoryId: [{
          required: false,
          message: `${that.$t('commmsg.pleaseInput')}${that.$t('inventorySchedule.fields.subcategory')}`,
          trigger: "change"
        }],
        executorId: [{
          required: false,
          message: `${that.$t('commmsg.pleaseInput')}${that.$t('inventorySchedule.fields.executor')}`,
          trigger: "change"
        }],
      }
    }
  },
  methods: {
    handleBack() {
      this.$router.pushAndClear(
          {name: "equipmentCategory"},
          {
            deleteItem: this.$route,
            current: this.$route,
          }
      );
    },
    checkAdd(formName) {
      let count = 0;
      formName.forEach((element) => {
        this.$refs[element].validate((valid) => {
          if (valid) {
            count++;
          } else {
            return false;
          }
        });
      });
      if (count >= 1) {
        this.messageBox({
          type: "success",
          title: "保存",
          content: "是否保存数据?",
        })
            .then(() => {
              this.handleDataSave();
            })
            .catch(() => {
              this.notification().warn({
                body: "已取消保存",
              });
            });
      }
    },
    handleDataSave() {
      let postData = {};
      //添加主分类
      if (this.isAddMainCategory) {
        Object.assign(postData, {
          categoryName: this.categoryForm.mainCategoryName,
          categoryNameEn: this.categoryForm.mainCategoryName,
          categoryNameOther: this.categoryForm.mainCategoryName,
          categoryCode: this.categoryForm.mainCategoryCode,
          directorDeptId: this.categoryForm.directorDeptId,
          parentId: null,
          attributeSettings: []
        })
        console.info(postData)
      } else if (this.isAddMiddleCategory) {
        Object.assign(postData, {
          categoryName: this.categoryForm.middleCategoryName,
          categoryNameEn: this.categoryForm.middleCategoryName,
          categoryNameOther: this.categoryForm.middleCategoryName,
          categoryCode: this.categoryForm.middleCategoryCode,
          parentId: this.categoryForm.mainCategoryId["id"],
          directorDeptId: this.categoryForm.directorDeptId,
          attributeSettings: []
        })
        console.info(postData)
      } else {
        //添加子分类
        Object.assign(postData, {
          categoryName: this.categoryForm.subcategoryName,
          categoryNameEn: this.categoryForm.subcategoryName,
          categoryNameOther: this.categoryForm.subcategoryName,
          categoryCode: this.categoryForm.subCategoryCode,
          directorDeptId: this.categoryForm.directorDeptId,
          parentId: this.categoryForm.middleCategoryId['id'],
          attributeSettings: []
        })

      }
      addEquipmentCategory(postData).then(result => {
        const {code, data,status, errMsg} = result;
        if (status === 200 && data.resultCode === 0) {
          this.notification().success({
            body: "保存成功",
          });
          this.handleBack();
        } else {
          this.notification().error({
            body: data.errMsg,
          });
        }
      })
    },
    handleReset(formName) {
      if (this.operateType === "add") {
        formName.forEach((element) => {
          this.$refs[element].resetFields();
        });
      }
    },
    initSelectData() {
      this.initCategoryList();
      this.initUserList();
      this.loadDepartmentData();
    },
    initCategoryList() {
      this.getMainCategoryList();//获取大分类
      this.getMiddleCategoryList();//获取中分类
    },
    initSubcategory(categoryId) {

    },
    initBrandList() {
      this.brandList.push({
            id: '1',
            label: '华硕',
            value: '华硕',
            key: 'asus'
          },
          {
            id: '2',
            label: '小米',
            value: 'xiaomi',
            key: 'xiaomi'
          })
    },
    initConditionList() {
      this.conditionList.push(
          {
            id: '1',
            label: '正常',
            value: 'ok',
            key: 'ok'
          }, {
            id: '2',
            label: '停用',
            value: 'stop',
            key: 'stop'
          }
      )
    },
    initUserList() {
      this.executorList.push(
          {
            id: '1',
            label: 'user1',
            value: '1',
            key: '1'
          }, {
            id: '2',
            label: 'user2',
            value: '2',
            key: '2'
          }
      )
    },
    handleDetailTableSelectionChange() {

    },
    selectCheckBox() {

    },
    handleDetailAdd() {
      this.equipmentImportDialog.equipmentDialog = true
    },
    handleCancleWorCenter() {
      this.equipmentImportDialog.equipmentDialog = false

    },
    mainCategoryBlur(e) {

    },
    loadDepartmentData() {
      getDepartmentList({
        page: 0,
        pageSize: 0
      }).then(res => {
        const {status, code, data} = res
        if (status === 200) {
          if (data != null && data.data.length > 0) {
            this.departmentOptionList = data.data.map((v) => {
              return {
                label: v.departmentName,
                value: v.id.label
              }
            });
            this.$forceUpdate();
          } else {
            this.departmentOptionList = []
          }
        }
      })
    },
    onMainCategoryChange(e) {
      console.info(e)
      let dataType = Object.prototype.toString.call(e);
      console.info(dataType)
      if ('[object String]' === dataType) {
        this.isAddMainCategory = true
        this.categoryForm.mainCategoryCode = null
        this.categoryForm.mainCategoryName = e;
        this.categoryForm.middleCategoryId = null;
        this.categoryForm.middleCategoryName = null;
        this.categoryForm.middleCategoryCode = null;
        this.categoryForm.subCategoryCode = null;
        this.categoryForm.subcategoryName = null;
        this.categoryForm.subcategoryId = null;
        // this.categoryForm.directorDeptId
      } else {//添加middle
        this.isAddMainCategory = false
        this.isAddMiddleCategory = true;
        // this.categoryForm.middleCategoryCode = e["code"]
        // this.categoryForm.middleCategoryName = e.label;
        this.categoryForm.mainCategoryCode = e["code"]
        this.categoryForm.mainCategoryName = e["label"];
        console.info(this.categoryForm)
        // this.categoryForm.mainCategoryId = e["id"];
        this.categoryForm.middleCategoryId = null;
        this.categoryForm.middleCategoryCode = null;
        this.categoryForm.middleCategoryName = null;
        this.loadMiddleCategoryByParent(e["id"])
        //加载中分类
      }
    },
    mainCategoryChange(e) {
      console.info(e)
      let dataType = Object.prototype.toString.call(e);
      if (e != null) {
        if ('[object String]' === dataType) {
          this.isAddMiddleCategory = true
          this.categoryForm.middleCategoryCode = ""
          this.categoryForm.middleCategoryName = e;
          console.info("添加middle")
        } else {
          this.isAddMiddleCategory = false
          console.info("添加子类的")
          this.categoryForm.middleCategoryCode = e["code"]
          this.categoryForm.middleCategoryName = e.label
        }
      }
    },
    getMainCategoryList() {//获取主分类下拉框
      let queryParams = {
        page: 0,
        pageSize: 0
      }
      getEquipmentCategoryList(queryParams).then(res => {
        const {status, data} = res
        if (status === 200) {
          let tempList = data.data;
          if (tempList != null && tempList.length > 0) {
            let result = tempList.filter(s => s["parentId"] == null || s['parentId'] === "")
                .map((res) => {
                  return {
                    id: res.id,
                    label: res.categoryName,
                    value: res.id,
                    key: res.id,
                    code: res.categoryCode,
                    data: res
                  }
                })
            this.mainCategoryParentList = [...result];
          }
        }
      })
    },
    getMiddleCategoryList() {//获取主分类下拉框
      let queryParams = {
        page: 0,
        pageSize: 0
      }
      getEquipmentCategoryList(queryParams).then(res => {
        const {status, data} = res
        if (status === 200) {
          let tempList = data.data;
          if (tempList != null && tempList.length > 0) {
            let treeData = arrayToTree(tempList, {
              parentProperty: 'parentId',
              childrenProperty: 'children'
            })
            console.info(treeData)
            let categoryList = []
            treeData.forEach((item) => {
              console.info(item)
              if (item != null && item.children != null) {
                for (let i = 0; i < item.children.length; i++) {
                  console.info(i)
                  let res = item.children[i];
                  if (res != null) {
                    let tItem = {
                      id: res.id,
                      label: res.categoryName,
                      value: res.id,
                      key: res.id,
                      code: res.categoryCode,
                      data: res,
                      parentId: item.id,
                    }
                    categoryList.push(tItem);
                  }
                  console.info(this.fullMiddleCategoryList)
                }
              }
            })
            this.fullMiddleCategoryList = [...categoryList]
            console.info(this.fullMiddleCategoryList)
          }
        }
      })
    },
    loadMiddleCategoryByParent(id) {
      if (this.fullMiddleCategoryList != null && this.fullMiddleCategoryList.length > 0) {
        console.info(this.fullMiddleCategoryList);
        this.middleCategoryList = [...this.fullMiddleCategoryList.filter(s => s["parentId"] != null && s["parentId"] === id)];
      }
    }
  }
}
</script>
<style lang="scss">

.equipment-category-add-container {
  .operate {
    padding: 16px;

    .el-button {
      margin: 0 8px 0 0;
    }
  }

  .info {
    background-color: #ffffff;
    padding: 16px;

    .equipmentFormTop {
      .el-form-item {
        margin: 0 24px 16px 0;
      }
    }

    .equipment-main-form {
      .el-form-item {
        margin: 0 24px 16px 0;

        .el-select {
          width: 320px;
        }

        .el-input {
          width: 320px;
        }
      }
    }
  }

  .equipment-query-form {
    .el-form-item__label {
      padding: 0;
    }
  }
}
</style>
